import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

const ImagesOuterWrapper = styled.div`
  max-width: 1920px;
  /* width:100%; */
  position:relative;
  z-index: 1;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-bottom: 7rem;
  }

  @media only screen and (min-width: 1920px){
    left: 50%;
    transform: translateX(-50%);
  }
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  width:100%;
  top:50%;
  transform: translateY(-50%);
  z-index: -1;
  display:flex;
  justify-content: center;
  align-items: center;

  .bg_image{
    @media only screen and (max-width: 768px){
      height: 100%;
    }
  }

  @media only screen and (max-width: 768px){
    height: 100%;
  }
`;

const ImageGallery = styled.div`
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  left:50%;
  transform: translateX(-50%);
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 2;

  .gallery__single_image{
    box-shadow: ${({ border }) => border === false ? "none" : "0px 9px 35px rgba(0, 0, 0, 0.25)"};
  }

  @media only screen and (max-width: 768px){
    display: none;

  }
`;

const OrangeTint = styled.div`
  position: absolute;
  width:100%;
  height:100%;
  top:50%;
  transform: translateY(-50%);
  background-color: var(--orange);
  opacity: 0.5;
  z-index: 0;
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      alt="nextArrow"
      className={`${className} custom-right-arrow`}
      onClick={onClick}
      style={{ ...style }}
    >
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      alt="prevArrow"
      className={`${className} custom-left-arrow`}
      onClick={onClick}
      style={{ ...style }}
    >
    </div>
  );
}

const MobileImageGallery = styled.div`
  padding-left: ${({ addPadding }) => `${addPadding}`};
  padding-right: ${({ addPadding }) => `${addPadding}`};
  margin-bottom: 5rem;

  .single_image{
    /* testing this value for shadow... */
    box-shadow: ${({ border }) => border === false ? "none" : "0px 9px 35px rgba(0, 0, 0, 0.25)"};
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .slick-next{
    margin-top: 1.25rem;
    top:100%;
    right:30%;
    border-radius: 3rem;

    &::before{
      content:""
    }
  }

  .custom-right-arrow{
    border-bottom: 10px solid transparent;
    border-left: 10px solid gray;
    border-top: 10px solid transparent; 
    height: 0;
    width: 0;

    &:hover{
      border-left: 10px solid var(--orange);
    }
  }

  .custom-left-arrow{
    border-bottom: 10px solid transparent;
    border-right: 10px solid gray;
    border-top: 10px solid transparent; 
    height: 0;
    width: 0;

    &:hover{
      border-right: 10px solid var(--orange);
    }
  }

  .slick-prev{
    margin-top: 1.25rem;
    top:100%;
    left:30%;
    border-radius: 3rem;

    &::before{
      content:""
    }
  }

  .mobile_image__container{
    
  }

  @media only screen and (min-width: 769px){
    display:none;
  }
`;

const Images = ({ data }) => {
  const settings = {
    autoplay: false,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
  };
  return (
    <ImagesOuterWrapper>
      {data.imageBackground && <BackgroundImageContainer>
        <GatsbyImage alt={data.imageBackground?.title} className="bg_image" image={getImage(data.imageBackground?.gatsbyImageData)}/>
        <OrangeTint></OrangeTint>
      </BackgroundImageContainer>}
      <ImageGallery border={data.addBorder} maxWidth={data.maxWidth}>
        {data.imageGallery.map((image, index) => {
          return (
            <GatsbyImage alt={image.title} className="gallery__single_image" image={getImage(image.gatsbyImageData)} key={index}/>
          );
        })}
      </ImageGallery>
      <MobileImageGallery addPadding={data.addPadding} border={data.addBorder}>
        <Slider {...settings}>
          {data.imageGallery.map((image, index) => {
            return (
              <div className="mobile_image__container" key={index}>
                <GatsbyImage alt={image.title} className="single_image" image={getImage(image.gatsbyImageData)}/>
              </div>
            );
          })}
        </Slider>
      </MobileImageGallery>     
    </ImagesOuterWrapper>
  );
};

export default Images;
