import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const CareersGRPWrapper = styled.div`
  max-width: 1920px;

  .grp_bodyText{
    margin-top: 3rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    /* width:90%; */
    text-align: left;
    padding: 0 5rem;

    @media only screen and (max-width: 1250px){
      font-size: 21px;
    }

    @media only screen and (max-width: 1250px){
      font-size: 19px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 18px;
      /* width:87%; */
      text-align: center;
      padding: 0 5.2rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 18px;
      text-align: center;
      padding: 0 2rem;
      margin-top: 1.5rem;
    }
  }

  .programs__container{
    display:flex;
    width:100%;
    justify-content: space-evenly;
    align-items: baseline;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @media only screen and (max-width: 768px){
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: baseline;
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }
  }

  .grp__container{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;

    @media only screen and (max-width: 768px){
      margin-bottom: 3rem;
    }
  }

  .grp_image__container{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .grp__image{
    @media only screen and (min-width: 769px){
      max-width: 100px;
    }

    @media only screen and (max-width: 500px){
      max-width: 80px;
    }
  }

  .grp__title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 20px;
      margin-top: 2rem;
      margin-bottom: 5rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 15px;
    }
  }

  .hire_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 2rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
      padding: 0 20px;
      margin-bottom: 3rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 30px;
      padding: 0 3rem;
    }
  }

  .view_position_button{
    width: 100%;

    @media only screen and (min-width: 1025px){
      max-width: 316px;
    }

    @media only screen and (max-width: 1024px){
      max-width: 250px;
    }
    
  }
  
  .hiring__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;

    @media only screen and (max-width: 768px){
      margin-bottom: 7rem;
    }

    @media only screen and (max-width: 500px){
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

`;

const CareersGRP = ({ content }) => {
  const { imageAssets, bodyText, cardComponents } = content[0];
  return (
    <CareersGRPWrapper>
      <GatsbyImage alt={imageAssets[0].title} image={getImage(imageAssets[0].gatsbyImageData)}/>
      <p className='grp_bodyText'>{bodyText[0].text}</p>
      <div className="programs__container">
        {cardComponents.references.map((grp, index) => {
          const { cardTitle, cardImage } = grp;
          return (
            <div className="grp__container" key={index}>
              <div className="grp_image__container">
                <GatsbyImage alt={cardTitle} className='grp__image'image={getImage(cardImage.gatsbyImageData)}/>
              </div>
              
              <p className='grp__title'>{cardTitle}</p>
            </div>
          );
        })}
      </div>
      
      <div className="hiring__container">
        <h4 className='hire_text'>{bodyText[1].text}</h4>
        <Link to="/careers#hiring"><button className='hire_button view_position_button'>{bodyText[2].text}</button></Link>
      </div>
     
    </CareersGRPWrapper>
  );
};

export default CareersGRP;