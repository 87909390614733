import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Background from "../background";

const CareersHeroWrapper = styled.div`
  max-width:1920px;
  .desktop{
    position: relative;
    display:grid;
    grid-template-columns: 56% 1fr;
    z-index: 0;

    .hero__image_container{
      position:relative;
      width:100%;
      height:100%;
      z-index: 1;
    }

    .hero__image--desktop{
      position:absolute;
      width:90%;
      height:auto;
      aspect-ratio: 607/487;
      border-radius: 3rem;
      top:2%;
      right:3%;
    }

    @media only screen and (max-width: 1024px){
      grid-template-columns: 65% 1fr;
    }

    @media only screen and (max-width:768px){
      display:none;
    }

    @media only screen and (max-width:1440px){
      .hero__image{
        width:95%;
        height:auto;
      }  
    }
  }

  .mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;

    h1{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.005em;
      color: var(--orange);
      padding-top: 3rem;
      /* padding: 2rem 4rem 0 4rem; */
    }

    h4{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 105.4%;
      color: var(--blue);
      text-align: center;
      letter-spacing: -0.005em;
      margin-bottom: 2rem;

      @media only screen and (min-width: 501px) and (max-width: 768px){
        font-size: 50px;
        margin-bottom: 5rem;
      }
    }

    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 152.9%;
      text-align: center;
      letter-spacing: -0.005em;
      padding: 0 2rem;
      margin-bottom: 2rem;

      @media only screen and (min-width: 501px){
        margin-top: 1.5rem;
        padding: 0 7.4rem;
        margin-bottom: 4rem;
      }
    }

    .hero_image__container{
      width:100%;
    }

    .hero__image--mobile{
      width: 100%;
      margin-bottom: 1rem;
    }

    @media only screen and (min-width: 769px){
      display: none;
    }
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }
`;

const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 29%;
  height: 85%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }
`;

const DesktopRowOne = styled.div`
  display:flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding-top: 10rem;
  
  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: var(--orange);
    text-align: left;
    width: 100%;
    padding-left: 5rem;
    margin-bottom: 1rem;
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 105%;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
    padding-left: 5rem;

    @media only screen and (max-width: 1250px){
      font-size:40px;
    }

    @media only screen and (max-width: 1024px){
      font-size:35px;
    }
  }

  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    text-align: left;
    width: 87%;
    margin-top: 0;
    margin-bottom: 3rem;
    padding-left: 5rem;

    @media only screen and (max-width: 1250px){
      font-size:22px;
    }

    @media only screen and (max-width: 1024px){
      font-size:20px;
    }
  }

  .button__container{
    width: 100%;
    padding-left: 5rem;
  }

  .our_company_button{
    @media only screen and (min-width: 1025px){
      width: 100%;
    }
  }

  @media only screen and (max-width: 500px){
    align-items: center;
  }

  @media only screen and (min-width: 1441px){
    padding-right: 5rem;
  }
`;

const AboutCompany = styled.div`
  margin-top: 7rem;
  margin-bottom: 5rem;
  display:flex;
  width:100%;
  justify-content: space-evenly;
  align-items: baseline;
  max-width: 1920px;

  .info__container{
    display:flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 1024px){
      width: 25%;
    }

    @media only screen and (max-width: 768px){
      width: 58%;
    }

    @media only screen and (max-width: 500px){
      width: 75%;
    }
  }

  .info__container:last-child{
    grid-column: 1/3;
    @media only screen and (max-width: 768px){
      width: 29%;
    }

    @media only screen and (max-width: 500px){
      width: 38%;
    }
    
  }

  .info__title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 152.9%;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--blue);
    margin-top: 1.5rem;

    @media only screen and (max-width: 1250px){
      font-size: 28px;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    @media only screen and (max-width: 1024px){
      font-size: 24px;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }


    @media only screen and (max-width: 768px){
      font-size: 25px;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 768px){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    align-items: baseline;
  }

  @media only screen and (min-width: 1441px){
    margin-top: 11rem;
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CareersHero = ({ content }) => {
  const { bodyText, imageAssets, cardComponents, orangeBackgroundRectangle } = content[0];
  return (
    <>
      <CareersHeroWrapper>
        {/* mobile */}
        <div className="mobile">
          <Background/>
          <h1>{bodyText[0].text}</h1>
          <h4>{bodyText[1].text}</h4>
          <div className="hero_image__container">
            <GatsbyImage alt={imageAssets[0].title} className="hero__image--mobile" image={getImage(imageAssets[0].gatsbyImageData)}/>
          </div>
          <p>{bodyText[2].text}</p>
          <Link to="/our-company"><button className='hire_button'>{bodyText[3].text}</button></Link>
        </div>

        {/* desktop */}
        <div className="desktop">
          <Background/>
          <DesktopRowOne>
            <h1>{bodyText[0].text}</h1>
            <h4>{bodyText[1].text}</h4>
            <p>{bodyText[2].text}</p>
            <div className="button__container">
              <Link to="/our-company"><button className='hire_button our_company_button'>{bodyText[3].text}</button></Link>
            </div>
            
          </DesktopRowOne>
          <div className='hero__image_container'>
            <img alt={imageAssets[0].title} className="hero__image hero__image--desktop" src={imageAssets[0].url}/>
          </div>
          <OrangeDesktopBackground>
            <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
          </OrangeDesktopBackground>
        </div>
      </CareersHeroWrapper>
      <AboutCompany>
        {cardComponents.references.map((info, index) => {
          const { cardTitle, cardImage } = info;
          return (
            <div className="info__container" key={index}>
              <GatsbyImage alt={cardTitle} className='info__image' image={getImage(cardImage.gatsbyImageData)}/>
              <p className='info__title'>{cardTitle}</p>
            </div>
          );
        })}
      </AboutCompany>
    </>
  );
};

export default CareersHero;