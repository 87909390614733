import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const OcProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7rem;
  max-width: 1920px;

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 129.9%;
    /* or 39px */
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 1rem;
    padding: 0 1rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
      padding: 0 4.4rem;
    }

    @media only screen and (min-width: 769px){
      font-size: 35px;
      width:100%;
      text-align: left;
      padding: 0 5rem;
    }

    @media only screen and (min-width: 1025px){
      font-size: 40px;
      width:100%;
      text-align: left;
      padding: 0 5rem;
    }

    @media only screen and (min-width: 1251px){
      font-size: 45px;
      width:100%;
      text-align: left;
      padding: 0 5rem;
    }
  }

  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 152.9%;
    /* or 28px */
    text-align: center;
    letter-spacing: -0.005em;
    padding: 0 1.8rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 6rem;
    }

    @media only screen and (min-width: 769px){
      font-size: 19px;
      text-align: left;
      padding: 0 5rem;
    }

    @media only screen and (min-width: 1025px){
      font-size: 21px;
      text-align: left;
      padding: 0 5rem;
    }

    @media only screen and (min-width: 1251px){
      font-size: 23px;
      text-align: left;
      padding: 0 5rem;
    }
  }

  .bottom_bodyText{
    margin-bottom: 2rem;
  }

  .button__container{
    width: auto;
  }

  .hire_button--desktop{
    @media only screen and (min-width: 1025px){
      width: 100%;
    }
  }

  @media only screen and (min-width: 769px){
    align-items: flex-start;

    .button__container{
      padding-left: 5rem;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px){
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: 500px){
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: 1920px){
    position:relative;
    left:50%;
    transform: translateX(-50%);
  }
`;

const OcProcess = ({ content }) => {
  const { bodyTexts } = content[0];
  return (
    <OcProcessWrapper>
      <h4>{bodyTexts[0].text}</h4>
      <p>{bodyTexts[1].text}</p>
      <p className='bottom_bodyText'>{bodyTexts[2].text}</p>
      <div className="button__container">
        <Link to="/contact-us"><button className='hire_button hire_button--desktop'>{bodyTexts[3].text}</button></Link>
      </div>
      
    </OcProcessWrapper>
  );
};

export default OcProcess;