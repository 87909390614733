import React from "react";
import styled from "styled-components";

const BannerWrapper = styled.div`
  /* margin-top: 3rem; */
  max-width: 1920px;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--blue);
  padding-top: ${({ bannerBottomText }) => bannerBottomText === null ? "3rem" : "0"};
  padding-bottom: ${({ bannerBottomText }) => bannerBottomText === null ? "3rem" : "0"};
  position: relative;
  z-index: 0;

  .orange_container{
    background-color: var(--orange);
    border-radius: 1rem;
    width:32%;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @media only screen and (max-width: 768px){
      position: relative;
      width: auto;
      top:0%;
      border-radius: 3rem;
      margin-top: 0;
      margin-bottom: 0;
      transform: ${({ bannerBottomText }) => bannerBottomText === null ? "translateY(0%);" : "translateY(-50%);"};
    }

    @media only screen and (max-width: 500px){
      width: 75%;
    }
  }

  .orange_container h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px !important;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    padding: 0.5rem 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 40px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 35px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
      margin-bottom: 0;
    }
  }

  .bottom_text__container{
    margin-bottom: 3rem;

    @media only screen and (max-width: 768px){
      margin-bottom: 1.5rem;
    }
  }

  .bottom_text__container p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px !important;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;

    @media only screen and (max-width: 1250px){
      font-size: 33px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 29px !important;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 30px !important;
      padding: 0 9.5rem;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px){
      font-size: 25px !important;
      padding: 0 2rem;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Banner = ({ data }) => {
  const { orangeContainerText, bannerBottomText } = data;
  return (
    <BannerWrapper bannerBottomText={bannerBottomText}>
      <div className="orange_container">
        <h2>{orangeContainerText}</h2>
      </div>
      {bannerBottomText && <div className="bottom_text__container">
        <p>{bannerBottomText}</p>
      </div>}
    </BannerWrapper>
  );
};

export default Banner;