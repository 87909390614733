import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import OsCard from "./osCard";
import Background from "../background";

const OsExpertiseWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;

  .blue_background__container{
    background-color: var(--blue);
    position: relative;
    z-index: 0;
  }

  .blue_background__text_container{
    padding-left: 5rem;
    display:flex;
    width:100%;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 0;

    @media only screen and (max-width: 768px){
      padding-left: 0;
    }
  }

  .blue_background__header{
    margin-top: 2rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 129.9%;
    /* or 39px */
    text-align: center;
    letter-spacing: -0.005em;
    color:#ffffff;
    padding: 0 1rem;

    @media only screen and (min-width: 769px){
      font-size: 35px;
      padding: 0;
      padding-top: 5rem;
      text-align: left;
      width: 48%;
    }

    @media only screen and (min-width: 1025px){
      font-size: 40px;
      padding: 0;
      padding-top: 5rem;
      text-align: left;
      width: 51%;
    }

    @media only screen and (min-width: 1251px){
      font-size: 45px;
      padding: 0;
      padding-top: 5rem;
      text-align: left;
      width: 48%;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
      padding: 0 5.5rem;
      margin-bottom: 2rem;
    }
  }

  .blue_background__paragraph{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 152.9%;
    /* or 28px */

    text-align: center;
    letter-spacing: -0.005em;
    color:#ffffff;
    padding: 0 1.6rem;
    margin-bottom: 3rem;

    @media only screen and (min-width: 769px){
      font-size: 19px;
      padding: 0;
      text-align: left;
      width: 53%;
    }

    @media only screen and (min-width: 1025px){
      font-size: 21px;
      padding: 0;
      text-align: left;
      width: 53%;
    }

    @media only screen and (min-width: 1251px){
      font-size: 23px;
      padding: 0;
      text-align: left;
      width: 53%;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 7.2rem;
      margin-bottom: 3.5rem;
    }
  }

  .cards__container{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -7rem;
    padding-left: 0rem;
    position:relative;
    z-index: 0;

    @media only screen and (min-width: 769px){
      display:grid;
      grid-template-columns: repeat(2, 350px);
      row-gap: 2.5rem;
      column-gap: 1rem;
      place-items: center;
      width:100%;
      padding-left:4rem;

    }
    
    @media only screen and (min-width: 1025px){
      display:grid;
      grid-template-columns: repeat(2, 450px);
      row-gap: 2.5rem;
      column-gap: 3rem;
      place-items: center;
      width:100%;
      padding-left:3rem;

    }

    @media only screen and (min-width: 1251px){
      display:grid;
      grid-template-columns: repeat(2, 500px);
      row-gap: 2.5rem;
      column-gap: 7rem;
      place-items: center;
      width:100%;
      padding-left:3rem;
    }
  }

  .mobile_img__container{
    @media only screen and (min-width: 769px){
      display:none;
    }
  }

  .mobile_img{
    width:100%;
  }

  .desktop_img{
    position: absolute;
    right: 0;
    top: -75px;
    width: 40%;

    @media only screen and (max-width: 768px){
      display:none;
    }
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }
`;

const OsExpertise = ({ content }) => {
  const { imageAssets, bodyText, cardComponents } = content[0];
  return (
    <OsExpertiseWrapper>
      <div className="blue_background__container">
        <div className="mobile_img__container">
          <GatsbyImage alt={imageAssets[0].title} className="mobile_img" image={getImage(imageAssets[0].gatsbyImageData)} />
        </div>
        
        <GatsbyImage alt={imageAssets[1].title} className="desktop_img" image={getImage(imageAssets[1].gatsbyImageData)} />
        <div className="blue_background__text_container">
          <Background color={"gray"} />
          <h2 className='blue_background__header'>{bodyText[0].text}</h2>
          <p className='blue_background__paragraph'>{bodyText[1].text}</p>
        </div>
        
        {/* card */}
        <div className="cards__container">
          <Background color={"gray"} />
          {cardComponents.references.map((card, index) => {
            return (
              <OsCard card={card} key={index}/>
            );
          })}
        </div>
      </div>
    </OsExpertiseWrapper>
  );
};

export default OsExpertise;