import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Background from "../background";

const OsPricingWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
  padding-left: 0rem;
  max-width: 1920px;
  margin-bottom: 3rem;
  position: relative;
  z-index: 0;

  .orange_divider{
    width: 35%;
    height: 10px;
    border-top: 6px solid var(--orange);
    margin-bottom: 3rem;

    @media only screen and (max-width: 768px){
      display:none;
    }
  }

  .text_container{
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing__header{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 105.4%;
    /* or 32px */

    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 2rem;

    @media only screen and (min-width: 769px) {
      width:100%;
      font-size: 35px;
      line-height: 129.9%;
      text-align:left;
    }

    @media only screen and (min-width: 1025px) {
      width:100%;
      font-size: 40px;
      line-height: 129.9%;
      text-align:left;
    }

    @media only screen and (min-width: 1251px) {
      width:100%;
      font-size: 45px;
      line-height: 129.9%;
      text-align:left;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
    }

  }

  .button__container{
    width: auto;

    @media only screen and (min-width: 769px){
      width: 100%;
    }
  }

  .hire_button--desktop{
    @media only screen and (min-width: 1025px){
      width: 100%;
    }
  }

  .pricing__upperText,.pricing__botText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 152.9%;
    /* or 28px */

    text-align: center;
    letter-spacing: -0.005em;
    color:#000000;
    padding: 0 10px;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 6.8rem;
    }

    @media only screen and (min-width: 769px){
      font-size: 19px;
      text-align:left;
      width:100%;
      padding: 0;
    }

    @media only screen and (min-width: 1025px){
      font-size: 21px;
      text-align:left;
      width:100%;
      padding: 0;
    }

    @media only screen and (min-width: 1251px){
      font-size: 23px;
      text-align:left;
      width:100%;
      padding: 0;
    }
  }

  .pricing__botText{
    margin-bottom: 2rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-bottom: 5rem;
  }

  @media only screen and (min-width: 769px){
    padding:0 5rem;
    align-items: flex-start;
    margin-bottom: 5rem;
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }

`;

const OsPricing = ({ content }) => {
  const { bodyText } = content[0];
  return (
    <OsPricingWrapper>
      <Background/>
      <div className="orange_divider"></div>
      
      <h2 className='pricing__header'>{bodyText[0].text}</h2>
      <p className='pricing__upperText'>{bodyText[1].text}</p>
      <p className='pricing__botText'>{bodyText[2].text}</p>
      
      <div className="button__container">
        <Link to="/contact-us"><button className='hire_button hire_button--desktop'>Hire Us</button></Link>
      </div>
    </OsPricingWrapper>
  );
};

export default OsPricing;