import React from "react";
import HomeCard from "./homeCard";
import styled from "styled-components";
import { Link } from "gatsby";
import Background from "../background";

const HomeRecentWorkWrapper = styled.div`
  max-width:1920px;
  background-color: var(--orange);
  /* margin-top: 2rem; */
  width:100%;
  height: 434px;
  display:flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .homeRecentWork__container{
    width:100%;
    position: relative;
    z-indeX: 0;

    p{
      padding-top: 3rem;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.005em;
      color: rgba(255, 255, 255, 0.7);

      @media only screen and (min-width: 901px){
        text-align: left;
        width:100%;
        padding-left: 5rem;
      }      
    }

    h2{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 135%;
      text-align: center;
      letter-spacing: -0.005em;
      color: #ffffff;
      padding: 0 0.5rem;
      width: 100%;

      @media only screen and (min-width: 901px){
        font-size: 35px;
        text-align: left;
        width:58%;
        padding-left: 5rem;
        margin-bottom: 0;
      }

      @media only screen and (min-width: 1025px){
        font-size: 40px;
        text-align: left;
        width:53%;
        padding-left: 5rem;
        margin-bottom: 0;
      }

      @media only screen and (min-width: 1251px){
        font-size: 45px;
        text-align: left;
        width:54%;
        padding-left: 5rem;
        margin-bottom: 0;
      }

      @media only screen and (min-width: 501px) and (max-width: 900px){
        font-size: 50px;
        padding: 0 3.4rem;
      }
    }

    @media only screen and (max-width: 900px){
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .cards__container{
    margin-top:4rem;
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    width:100%;
    position: relative;
    z-index: 0;
    
    
    @media only screen and (max-width: 900px){
      flex-direction: column;
    }
  }

  .hire_button{
    color: var(--orange);
    border: 1px solid #ffffff;
    background-color: #ffffff;
  }

  .hire_button:hover{
    color: #ffffff;
    background-color: var(--orange);
  }

  .case_study--desktop{
    margin-top: 1rem;

    @media only screen and (min-width: 901px){
      margin-left: 5rem;
    }

    @media only screen and (min-width: 769px){
      width: 100%;
    }
  }

  @media only screen and (max-width: 900px){
    height:auto;
    flex-direction: column;
  }

  @media only screen and (min-width:1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }
`;

const HomeRecentWork = ({ content }) => {
  const { bodyTexts, cardComponents } = content[0];
  return (
    <HomeRecentWorkWrapper>
      <Background/>
      <div className="homeRecentWork__container">
        <Background color={"gray"}/>
        <p>{bodyTexts[0].text}</p>
        <h2>{bodyTexts[1].text}</h2>
        <Link to="/case-studies"><button className='hire_button case_study--desktop'>See Case Studies</button></Link>
      </div>
      <div className="cards__container">
        <Background color={"gray"} show={"hidden"}/>
        {cardComponents.references.map((card, index) => {
          return (
            <HomeCard card={card} key={index} />
          );
        })}
      </div>
    </HomeRecentWorkWrapper>
  );
};

export default HomeRecentWork;