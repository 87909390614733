import React from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import CaseStudyCard from "../case-study-card";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Background from "../../background";

const HeroWrapper = styled.div`
  max-width: 1920px;
  display: grid;
  grid-template-columns: 65% 1fr;
  position: relative;
  margin-bottom: 3rem;

  .casestudy_card_container--desktop{
    position: relative;
    z-index: 1;
    
    @media only screen and (max-width: 768px){
      display: none;
    }
  }

  .casestudy_card_container--mobile{
    margin-bottom: 2rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-bottom: 5rem;
    }

    @media only screen and (min-width: 769px){
      display: none;
    }
  }

  @media only screen and (min-width: 1920px){
    left: 50%;
    transform: translateX(-50%);
  }

  @media only screen and (max-width: 1250px){
    grid-template-columns: 60% 1fr;
  }

  @media only screen and (max-width: 1024px){
    grid-template-columns: 50% 1fr;
  }

  @media only screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

`;
const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 29%;
  height: 52%;
  z-index: -1;

  .orange_image{
    width: 100%;
    position: relative;
    z-index: -1;
  }

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const RichTextContainer = styled.div`
  padding-top: 3rem;
  padding-left: 3rem;

  .client_name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px !important;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    color: var(--blue);
    text-align: left;
    margin-bottom: 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 40px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 35px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 40px;
      text-align: center;
    }

    @media only screen and (max-width: 500px){
      font-size: 40px;
      line-height: 105.4%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .case_study_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: var(--orange);
    text-align: left;
    margin-bottom: 1rem;

    @media only screen and (max-width: 768px){
      text-align: center;
    }
  }

  .rich_paragraph{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: #000000;
    text-align: left;
    margin-bottom: 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 22px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 18px;
      text-align: center;
      margin-bottom: 2rem;
      padding: 0 6.8rem;

    }
    

    @media only screen and (max-width: 500px){
      font-size: 18px;
      text-align: center;
      margin-bottom: 2rem;
      padding: 0 1.4rem;
    }
  }

  li{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .rich_ol{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: var(--blue);
    position: relative;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px;
      text-align: left;
      /* list-style-position: inside; */
      /* padding-left: 0; */
      /* list-style: none; */
      padding: 0 3rem;
      line-height: 102%;
    }

    @media only screena and (max-width: 500px){
      font-size: 25px;
      padding: 0 1rem;
    }
  }

   .rich_ol > li{
    margin-bottom: 1rem;
  }
/*
  .rich_ol > li::marker{
    color: #ffffff;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 35px;
    }
  } */

  .rich_ol > li > p{
    @media only screen and (min-width: 769px){
      margin-left: 1rem;
      margin-bottom: 0rem;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      /* padding: 0 7.1rem; */
      padding: 0 12px;
    }

    @media only screen and (max-width: 768px){
      margin-top: 1rem;
    }
  }

  /* .rich_ol > li::before{
    content: "";
    position: absolute;
    width:40px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3rem;
    background-color: orange;
    z-index: -1;

    @media only screen and (min-width: 769px){
      transform: none;
      left:-2px;
    }

    @media only screen and (min-width: 1025px){
      transform: none;
      left:-5px;
    }

    @media only screen and (min-width: 1251px){
      transform: none;
      left:-12px;

      width:50px;
      height: 50px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      width: 50px;
      height: 50px;
    }
  } */

  .rich_ul{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: var(--blue);
    list-style-type: square;
    padding-left: 5rem;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      /* list-style: none; */
      font-size: 18px;
      text-align: left;
      padding: 0 3rem;
    }

    @media only screen and (max-width: 500px){
      padding: 0rem;
    }
  }

  .rich_ul li> p{
     margin-bottom: 0rem;
  }

  .rich_ul li::marker{
    color: var(--blue);
  }

  @media only screen and (max-width: 768px){
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: 500px){
    margin-bottom: 0;
  }
`;

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className='client_name'>{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className='case_study_text'>{children}</h2>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <p className='rich_paragraph'>{children}</p>;
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className='rich_ol'>{children}</ol>;
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className='rich_ul'>{children}</ul>;
    },
  },
};

const Hero = ({ data }) => {
  const { clientName, richText, heroCardDesktopImage, heroCardTabletImage, heroCardMobileImage, heroCardLogoImage, orangeBackgroundRectangle } = data;
  const json_text = JSON.parse(richText.raw);
  const heroImages = { heroCardDesktopImage, heroCardLogoImage, heroCardMobileImage, heroCardTabletImage };
  return (
    <HeroWrapper>
      <Background/>
      <RichTextContainer>
        <p className='case_study_text'>Case Study</p>
        <h1 className='client_name'>{clientName}</h1>
        <div className="casestudy_card_container--mobile">
          <CaseStudyCard images={heroImages}/>
        </div>
        {documentToReactComponents(json_text, RICHTEXT_OPTIONS)}
      </RichTextContainer>
      <div className="casestudy_card_container--desktop">
        <CaseStudyCard images={heroImages}/>
      </div>
      <OrangeDesktopBackground>
        <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
      </OrangeDesktopBackground>
    </HeroWrapper>
  );
};

export default Hero;