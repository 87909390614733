import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
// import { StaticImage } from "gatsby-plugin-image";
// import Background from "../background";

const HomeLogoCarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 3rem;

  max-width:1920px;
  /* padding: 0 3rem; */

  /* add stuff here - START */
  
  position: relative;
  
  .loc{
    position: absolute;
    height: 800px;
    width: 120px;
    right: 0;
    top: -90px;
  }

  /* add stuff here - END */

  h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 105.4%;
    /* or 32px */

    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);

    @media only screen and (min-width: 769px){
      text-align: left;
      width:100%;
      padding-left: 5rem;
      font-size: 24px;
      margin-bottom: 3rem;
    }
  }

  .orange_bar{
    width:70px;
    position:relative;
    height: 7px;
    background: var(--orange);
    margin-bottom: 3rem;

    @media only screen and (min-width: 769px){
      display:none;
    }

  }

  .slick-next{
    margin-top: 1rem;
    top:-75px;
    left:95%;
    border-radius: 3rem;

    @media only screen and (max-width: 768px){
      top:100%;
      left:60%;
      transform: translateX(-50%);
    }

    &::before{
      content:""
    }
  }

  .custom-right-arrow{
    border-bottom: 10px solid transparent;
    border-left: 10px solid gray;
    border-top: 10px solid transparent; 
    height: 0;
    width: 0;

    &:hover{
      border-left: 10px solid var(--orange);
    }
  }

  .custom-left-arrow{
    border-bottom: 10px solid transparent;
    border-right: 10px solid gray;
    border-top: 10px solid transparent; 
    height: 0;
    width: 0;

    &:hover{
      border-right: 10px solid var(--orange);
    }
  }

  .slick-prev{
    margin-top: 1rem;
    top:-75px;
    left:90%;
    border-radius: 3rem;

    @media only screen and (max-width: 768px){
      top:100%;
      left:40%;
      transform: translateX(-50%);
    }

    &::before{
      content:""
    }
  }

  @media only screen and (min-width: 769px){
    margin-top: 7rem;
  }

  @media only screen and (min-width:1920px){
    position:relative;
    left:50%;
    transform: translateX(-50%);
  }
`;
const HomeLogoCarouselInnerWrapper = styled.div`
  width:100%;
  
  /* .logo_container{
    height:100px;
  } */

  .logo_container img{
    position: relative;
    left:50%;
    transform: translateX(-50%);
    height:50px;
    width:200px;
    object-fit: contain;
  }  

`;
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      alt="nextArrow"
      className={`${className} custom-right-arrow`}
      onClick={onClick}
      style={{ ...style }}
    >
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      alt="prevArrow"
      className={`${className} custom-left-arrow`}
      onClick={onClick}
      style={{ ...style }}
    >
    </div>
  );
}

export default class HomeLogoCarousel extends Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      infinite: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToScroll: 3,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ],
      slidesToScroll: 5,
      slidesToShow: 5,
      speed: 1000,
    };
    const { imageAssets, bodyTexts } = this.props.content[0];
    return (
      <HomeLogoCarouselWrapper>
        <h2>{bodyTexts[0].text}</h2>
        <div className="orange_bar"></div>
        {/* <StaticImage alt="loc" className="loc" src="../../assets/images/home/02_logoCarousel/Ellipse 62.png"/> */}
        <HomeLogoCarouselInnerWrapper>
          <Slider {...settings}>
            {imageAssets.map((image, index) => {
              return (
                <div className="logo_container" key={index}>
                  <img alt={image.title} src={image.url}/>
                </div>
              );
            })}
          </Slider>
        </HomeLogoCarouselInnerWrapper>
      </HomeLogoCarouselWrapper>
    );
  }
}