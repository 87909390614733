import React from "react";
import styled from "styled-components";
import HuForm from "./huForm";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Background from "../background";

const HuContactWrapper = styled.div`
  max-width: 1920px;

  .desktop{
    position: relative;
    z-index: 0;
    display:grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 5rem;
    padding-top: 10rem;

    .hero__image--desktop{
      position:absolute;
      width: 50%;
      top:2%;
      right:3%;
      border-radius: 3rem;
      z-index: 1;
    }

    .desktop__right_col{
      display:grid;
      grid-template-rows: 1fr 1fr;
      place-items: center;
      padding-bottom: 5rem;
    }

    .right_col__bottom_container{
      grid-row: 2/3;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .right_col__top_text{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      line-height: 110%;
      letter-spacing: -0.005em;
      color: var(--blue);
      padding-right: 1rem;

      @media only screen and (max-width: 1250px){
        font-size: 40px;
      }

      @media only screen and (max-width: 1024px){
        font-size: 35px;
      }
    }

    .right_col__bottom_text{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 105%;
      letter-spacing: -0.005em;
      color: var(--blue);
      margin-bottom: 3rem;
      padding-right: 1.1rem;

      @media only screen and (max-width: 1250px){
        font-size: 35px;
      }

      @media only screen and (max-width: 1024px){
        font-size: 30px;
      }
    }

    .orange_divider{
      height:6px;
      width: 80%;
      background-color: var(--orange);
      border: 1px solid var(--orange);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @media only screen and (max-width:768px){
      display:none;
    }

    @media only screen and (max-width:1440px){
      .hero__image{
        width:95%;
        height:auto;
      }  
    }
  }

  .mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
    
    h1{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.005em;
      color: var(--orange);
      padding-top: 2rem;
    }

    h4{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 105.4%;
      /* or 42px */
      color: var(--blue);
      text-align: center;
      letter-spacing: -0.005em;
      margin-bottom: 3rem;

      @media only screen and (min-width: 501px) and (max-width: 768px){
        font-size: 50px;
      }
    }

    .top_text{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 110.4%;
      /* or 33px */
      color: var(--blue);
      text-align: center;
      letter-spacing: -0.005em;
      margin-bottom: 5rem;

      @media only screen and (min-width: 501px){
        font-size: 50px;
        padding: 0 8.8rem;
        margin-top: 2rem;
      }
    }

    .bot_text{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 105.4%;
      /* or 26px */
      color: var(--blue);
      text-align: center;
      letter-spacing: -0.005em;
      margin-bottom: 3rem;

      @media only screen and (min-width: 501px){
        font-size: 50px;
        margin-top: 2rem;
        margin-bottom: 5rem;
      }
    }

    @media only screen and (min-width: 769px){
      display: none;
    }
  }
  

  @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const DesktopRowOne = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  /* z-index: 0; */

  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: var(--orange);
    margin-bottom: 1rem;
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 63px;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 5rem;

    @media only screen and (max-width: 1250px){
      font-size: 40px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 35px;
    }
  }

  
`;

const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 25%;
  height: 46%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }

  @media only screen and (max-width: 768px){
    display:none;
  }
`;

const HuContact = ({ content }) => {
  const { imageAssets, bodyText, orangeBackgroundRectangle } = content[0];
  return (
    <HuContactWrapper>
      <div className="desktop">
        <Background/>
        <DesktopRowOne>
          {/* <Background/> */}
          <h1>{bodyText[0].text}</h1>
          <h4>{bodyText[1].text}</h4>
          <HuForm/>
        </DesktopRowOne>
        <GatsbyImage alt={imageAssets[0].title} className="hero__image--desktop" image={getImage(imageAssets[0].gatsbyImageData)}/>
        <OrangeDesktopBackground>
          <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
        </OrangeDesktopBackground>
        <div className="desktop__right_col">
          <div className="right_col__bottom_container">
            <h4 className='right_col__top_text'>{bodyText[2].text}</h4>
            <div className="orange_divider"></div>
            <h4 className='right_col__bottom_text'>{bodyText[3].text}</h4>
            <GatsbyImage alt={imageAssets[1].title} image={getImage(imageAssets[1].gatsbyImageData)}/>
          </div>
          
        </div>
      </div>

      <div className="mobile">
        <Background/>
        <h1>{bodyText[0].text}</h1>
        <h4>{bodyText[1].text}</h4>
        <HuForm/>
        <p className="top_text">{bodyText[2].text}</p>
        <p className="bot_text">{bodyText[3].text}</p>
      </div>
    </HuContactWrapper>
  );
};

export default HuContact;