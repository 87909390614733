import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Background from "../background";

const HomeHireWrapper = styled.div`
  max-width: 1920px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  position: relative;

  .ffg_logo{
    @media only screen and (min-width: 501px){
      width: 65px;
      height: 59px;
    }
  }

  p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--orange);

    @media only screen and (min-width: 501px){
      font-size: 24px;
    }
  }

  h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 129.9%;
    /* or 32px */
    margin-bottom: 2rem;

    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);

    @media only screen and (min-width: 769px){
      font-size: 60px;
      margin-bottom: 5rem;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
    }
  }

  .button__container{
    width: 100%;
  }

  .hire_button{
    
    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 2rem;
      margin-bottom: 5rem;
    }

    @media only screen and (max-width: 500px){
      margin-bottom: 2rem;
    }
  }

  .hire_button--desktop{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    
    @media only screen and (min-width: 1025px){
      width: 100%;
    }
  }
  .upper__container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
  }


  .client_image__container{
    height:900px;
    width:100%;
    position: relative;
    margin-top: 2rem;

    @media only screen and (max-width: 768px){
      display:none;
    }
  }

  .walkers_sb{
    position:absolute;
    top:0;
    left:2%;
    width: 22%;
    border-radius: 2rem;
    max-width: 303px;

    @media only screen and (max-width: 1024px){
      border-radius: 1rem;
    }

  }

  .asian_mart{
    position:absolute;
    top:10%;
    right:2%;
    width: 25%;
    border-radius: 2rem;
    max-width: 360px;

    @media only screen and (max-width: 1024px){
      border-radius: 1rem;
    }
  }

  .inmode{
    position:absolute;
    top:50%;
    left:17%;
    width: 25%;
    border-radius: 2rem;
    max-width: 360px;

    @media only screen and (max-width: 1024px){
      border-radius: 1rem;
    }
  }

  .perfect_storm{
    position:absolute;
    top:25%;
    left:50%;
    width: 20%;
    border-radius: 2rem;
    max-width: 280px;

    @media only screen and (max-width: 1024px){
      border-radius: 1rem;
    }
  }

  .btb{
    position:absolute;
    top:10%;
    left:30%;
    width: 15%;
    border-radius: 2rem;
    max-width: 212px;

    @media only screen and (max-width: 1024px){
      border-radius: 1rem;
    }
  }

  .lor{
    position:absolute;
    top:53%;
    left:73%;
    width: 11%;
    border-radius: 2rem;
    max-width: 158px;

    @media only screen and (max-width: 1024px){
      border-radius: 1rem;
    }
  }

  @media only screen and (min-width: 901px){
    margin-top: 600px;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-top: 7rem;
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

`;

const HomeHire = ({ content }) => {
  const { imageAssets, bodyTexts } = content[0];
  return (
    <HomeHireWrapper>
      {/* ffg logo image */}
      <div className="upper__container">
        <GatsbyImage alt={imageAssets[0].title} className="ffg_logo" image={getImage(imageAssets[0].gatsbyImageData)}/>
        {/* text block 1 */}
        <p>{bodyTexts[0].text}</p>
        {/* text block 2 */}
        <h2>{bodyTexts[1].text}</h2>
        {/* hire button */}
        <div className="button__container">
          <Link to="/contact-us"><button className='hire_button hire_button--desktop'>Hire Us</button></Link>
        </div>
      </div>

      <div className="client_image__container">
        <Background/>
        <GatsbyImage alt={imageAssets[1].title} className="walkers_sb" image={getImage(imageAssets[1].gatsbyImageData)}/>
        <GatsbyImage alt={imageAssets[2].title} className="asian_mart" image={getImage(imageAssets[2].gatsbyImageData)}/>
        <GatsbyImage alt={imageAssets[3].title} className="inmode" image={getImage(imageAssets[3].gatsbyImageData)}/>
        <GatsbyImage alt={imageAssets[4].title} className="perfect_storm" image={getImage(imageAssets[4].gatsbyImageData)}/>
        <GatsbyImage alt={imageAssets[5].title} className="btb" image={getImage(imageAssets[5].gatsbyImageData)}/>
        <GatsbyImage alt={imageAssets[6].title} className="lor" image={getImage(imageAssets[6].gatsbyImageData)}/>
      </div>
    </HomeHireWrapper>
  );
};

export default HomeHire;