import React from "react";
import Layout from "../layouts";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const FormSuccessWrapper = styled.div`
  position: relative;
  display:grid;
  grid-template-columns: 75% 1fr;
  padding-left: 5rem;
  padding-top: 10rem;
  margin-bottom: 7rem;
  max-width: 1920px;

  @media only screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-top: 2rem;
    margin-bottom: 0rem;
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  margin-bottom: 5rem;

  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: var(--orange);
    width: 100%;

    @media only screen and (max-width: 768px){
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 108.9%;
    letter-spacing: -0.005em;
    color: var(--blue);
    width: 100%;
    padding-right: 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 55px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 50px;
    }


    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
      text-align: center;
      margin-bottom: 5rem;
      padding: 0 2.3rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 24px;
      text-align: center;
      margin-bottom: 3rem;
      padding: 0;
    }
  }

  .top_text{
    margin-top: 0rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-bottom: 7rem;
    }

    @media only screen and (min-width: 769px){
      display: none;
    }
  }

  @media only screen and (max-width: 768px){
    align-items: center;
    margin-bottom: 0rem;
    padding: 0 1rem;
  }
`;

const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 25%;
  height: 100%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }
  

  @media only screen and (max-width: 768px){
    display:none;
  }
`;

const FormSuccess = ({ data }) => {
  const { nodes } = data.allContentfulHireUsPage;
  const hero = nodes.filter(node => node.sectionTitle === "01_Hire_Us - Form");
  return (
    <Layout>
      <FormSuccessWrapper>
        <TextContainer>
          <h1>CONTACT</h1>
          <h4 className='top_text'>Let&apos;s chat</h4>
          <h4 className='mid_text'>We are excited you chose us as your extended Growth Team.</h4>
          <h4 className='bot_text'>We will be contacting you ASAP on the next steps!</h4>    
        </TextContainer>
        <OrangeDesktopBackground>
          <GatsbyImage alt={hero[0].orangeBackgroundRectangle.title} className="orange_image" image={getImage(hero[0].orangeBackgroundRectangle.gatsbyImageData)}/>
        </OrangeDesktopBackground>  
      </FormSuccessWrapper>
    </Layout>
  );
};

export default FormSuccess;

export const query = graphql`
query formSuccessQuery {
  allContentfulHireUsPage {
    nodes {
      sectionTitle
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
    }
  }
}
`;