import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const OcJoinWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 1rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
      padding: 0 7.7rem;
      margin-bottom: 3rem;
    }
  }

  .button__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .view_position_button{
    width: 100%;

    @media only screen and (min-width: 1025px){
      max-width: 316px;
    }

    @media only screen and (max-width: 1024px){
      max-width: 250px;
    }
    
  }

  .open_positions{
    width:70%;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (min-width: 501px) and (max-width: 768px){
      width: 34%;
    }
  }

  @media only screen and (min-width: 769px){
    display: none;
  }
`;

const OcJoin = ({ content }) => {
  const { bodyTexts } = content[0];
  return (
    <OcJoinWrapper>
      <h4>{bodyTexts[0].text}</h4>
      <div className="button__container">
        <Link to="/careers#hiring"><button className='hire_button view_position_button'>{bodyTexts[1].text}</button></Link>
      </div>
      
    </OcJoinWrapper>
  );
};

export default OcJoin;