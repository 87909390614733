import React from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

const LevelCardWrapper = styled.div`
  max-width: 1920px;
  position: relative;
  margin-bottom: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  

  @media only screen and (min-width: 1920px){
    left: 50%;
    transform: translateX(-50%)  
  }

  @media only screen and (max-width: 768px){
    margin-bottom: 3rem;
    padding: 0 10px;
  }
`;

const RichTextContainer = styled.div`
  background-color: var(--blue);
  max-width: 1300px;
  padding: 3rem;

  .rich_ul{
    color: var(--orange);
    font-size: 30px;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      list-style: none;
      padding-left: 0;
    }
  }

  .rich_ul h6{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 152.9%;
    letter-spacing: -0.005em;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-size: 25px;
      text-align: center;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px;
    }
  }

  .rich_p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: #ffffff;
    padding-left: 2rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-size: 25px;
      text-align: center;
      padding-left: 0rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 768px){
    max-width: 650px;
    padding: 4rem 5rem;

  }

  @media only screen and (max-width: 500px){
    max-width: 280px;
    padding: 1rem;

  }
`;

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className='rich_ul'>{children}</ul>;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className='rich_p'>{children}</p>;
    },
  },
};

const LevelCard = ({ data }) => {
  const json_text = JSON.parse(data.levelCardText.raw);
  return (
    <LevelCardWrapper>
      <RichTextContainer>
        {documentToReactComponents(json_text, RICHTEXT_OPTIONS)}
      </RichTextContainer>
    </LevelCardWrapper>
  );
};

export default LevelCard;